import { useEffect } from 'react'
import { getChainsConfig, type ChainInfo } from '@safe-global/safe-gateway-typescript-sdk'
import useAsync, { type AsyncResult } from '../useAsync'
import { logError, Errors } from '@/services/exceptions'
// import { FEATURES, GAS_PRICE_TYPE, RPC_AUTHENTICATION } from '@safe-global/safe-gateway-typescript-sdk'

// import { GAS_PRICE_TYPE, RPC_AUTHENTICATION } from '@safe-global/safe-gateway-typescript-sdk'
// import { FEATURES } from '@/utils/chains'

const getConfigs = async (): Promise<ChainInfo[]> => {
  const data = await getChainsConfig()
  // let addChains: ChainInfo[] = [
  //   {
  //     transactionService: 'https://safe-transaction.mainnet.gnosis.io',
  //     chainId: '6000',
  //     chainName: 'BounceBit Testnet',
  //     chainLogoUri: 'https://safe-transaction-assets.staging.5afe.dev/chains/1/chain_logo.png',
  //     shortName: 'BounceBit',
  //     l2: true,
  //     isTestnet: true,
  //     description: 'BounceBit Testnet',
  //     rpcUri: {
  //       authentication: RPC_AUTHENTICATION.NO_AUTHENTICATION,
  //       value: 'https://oracle-rpc-vip.bouncebitapi.com/',
  //     },
  //     safeAppsRpcUri: {
  //       authentication: RPC_AUTHENTICATION.NO_AUTHENTICATION,
  //       value: 'https://oracle-rpc-vip.bouncebitapi.com/',
  //     },
  //     publicRpcUri: {
  //       authentication: RPC_AUTHENTICATION.NO_AUTHENTICATION,
  //       value: 'https://oracle-rpc-vip.bouncebitapi.com/',
  //     },
  //     blockExplorerUriTemplate: {
  //       address: 'https://bbscan.io/address/{{address}}',
  //       txHash: 'https://bbscan.io/tx/{{txHash}}',
  //       api: 'https://bbscan.io/api?module={{module}}&action={{action}}&address={{address}}&apiKey={{apiKey}}',
  //     },
  //     nativeCurrency: {
  //       name: 'BB',
  //       symbol: 'BB',
  //       decimals: 18,
  //       logoUri: 'https://safe-transaction-assets.gnosis-safe.io/chains/5/currency_logo.png',
  //     },
  //     theme: { textColor: '#ffffff', backgroundColor: '#FBC02D' },
  //     gasPrice: [{ type: GAS_PRICE_TYPE.FIXED, weiValue: '24000000000' }],
  //     disabledWallets: [],
  //     features: [
  //       FEATURES.CONTRACT_INTERACTION,
  //       FEATURES.DOMAIN_LOOKUP,
  //       FEATURES.EIP1559,
  //       FEATURES.ERC721,
  //       FEATURES.SAFE_APPS,
  //       FEATURES.SAFE_TX_GAS_OPTIONAL,
  //       FEATURES.SPENDING_LIMIT,
  //       FEATURES.TX_SIMULATION,
  //       FEATURES.COUNTERFACTUAL,
  //       FEATURES.RELAYING,
  //     ],
  //   },
  // ]

  // return [...data.results, ...addChains] || []
  return [...data.results] || []
}

export const useLoadChains = (): AsyncResult<ChainInfo[]> => {
  const [data, error, loading] = useAsync<ChainInfo[]>(getConfigs, [])

  // Log errors
  useEffect(() => {
    if (error) {
      logError(Errors._620, error.message)
    }
  }, [error])

  return [data, error, loading]
}

export default useLoadChains
